module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.12_@types+webpack@4.41.33_babel-eslint@10.1.0_eslin_tbdopukwg2co5y4fkap6w6hxr4/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fröhlich ∧ Frei","short_name":"F∧F","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"/builds/fuf/www.froehlichundfrei.de/packages/fuf-website/src/img/favicons/favicon@2x.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8e35bc472d0b7f59f9e9910f4dc91eee"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-plausible@0.0.7_patch_hash=5nksvntxxj45al5k634a3jsu24_gatsby@5.12.12_@types+web_a7vx3uphad7cgzxfhrqxnl4j5q/node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"customDomain":"www.froehlichundfrei.de/stats","domain":"froehlichundfrei.de"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.12.12_@types+webpack@4.41.33_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_esli_4saxuzl5ochtolkrmbkshybxim/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
